/* eslint-disable */
import React from "react";
import "./landing.css";
import logo from "./momlogo.webp";
import { FaChartPie, FaEye, FaPen, FaBook } from "react-icons/fa";
import { Link } from "react-router-dom";

const HomePage: React.FC = () => {
  return (
    <div className="mom-newl-homepage">
      {/* Header */}
      <header className="mom-newl-header">
        <img src={logo} alt="Mind of the Market" className="mom-newl-logo" />
      </header>

      {/* Hero Section */}
      <section className="mom-newl-hero">
        <h1>
          What’s the One Missing Element in Your Copy That Could Skyrocket Trust
          and Conversions?
        </h1>
        <p>
          Imagine unlocking a method so powerful it’s captivated global
          audiences, generating billions in revenue over the last five decades,
          building trust in every message. The secret? It’s not about clever
          wording or flashy headlines. It’s something much deeper... <br />
          {""} <br />
          What if your copy could build trust with every word, naturally drawing
          your audience in and keeping them engaged? What if you had the exact
          tools to help make that happen?
        </p>
        <Link to={"/login"}>
          <button className="mom-newl-cta-button">Discover the Secret</button>
        </Link>
      </section>

      {/* Introduction Section */}
      <section className="mom-newl-introduction">
        <h2>Introducing Mind of the Market</h2>
        <p>
          While AI copywriting tools are abundant,{" "}
          <span className="fw-bold fst-italic">Mind of the Market</span> goes
          beyond the basics. Our AI doesn’t just generate text; it captures your
          audience’s unique voice, preferences, and emotional drivers, giving
          you the insights needed to build authentic connections with every
          message.
        </p>
        <p>
          By combining customizable templates, guided content creation, and
          powerful market research tools, we equip you to write copy that
          resonates — not just superficially, but at a deeper, trust-building
          level.
        </p>
      </section>

      {/* Features Section */}
      <section className="mom-newl-features">
        <h2>How We Help You Connect and Convert</h2>
        <div className="mom-newl-feature">
          <FaChartPie className="mom-newl-icon" />
          <h3>In-Depth Market Insights</h3>
          <p>
            Analyze consumer behavior and language patterns to uncover what
            truly drives your market, helping you speak directly to their needs
            and desires.
          </p>
        </div>
        <div className="mom-newl-feature">
          <FaEye className="mom-newl-icon" />
          <h3>Consumer Feedback Mining</h3>
          <p>
            Discover the emotions and motivations hidden in real consumer
            feedback, enabling you to tailor your messaging to resonate with
            authenticity.
          </p>
        </div>
        <div className="mom-newl-feature">
          <FaPen className="mom-newl-icon" />
          <h3>Guided Copywriting</h3>
          <p>
            Write with confidence using interactive tools and strategies honed
            by marketing legend Jay Abraham, helping you connect directly with
            your audience’s core values.
          </p>
        </div>
        <div className="mom-newl-feature">
          <FaBook className="mom-newl-icon" />
          <h3>Customizable Templates</h3>
          <p>
            Stay aligned with your audience’s needs and streamline your workflow
            with templates based on decades of strategic copywriting expertise.
          </p>
        </div>
        <p>
          Whether you're just starting out or you've been writing copy for
          years, <span className="fw-bold fst-italic">Mind of the Market</span>{" "}
          is designed to meet you where you are. From beginners looking for
          guidance to professionals who need a faster way to analyze their
          market, our platform looks to accommodate your needs, helping you grow
          and improve your skills at every step.
        </p>
      </section>

      {/* Jay Abraham Section with Video */}
      <section className="mom-newl-jay-abraham">
        <h2>Jay Abraham’s Strategies Brought to Life</h2>
        <p>
          Jay Abraham, a legendary marketing strategist, has spent decades
          honing the art of trust-building. His methodologies have transformed
          simple correspondences into high-converting messages that generate
          six-figure returns over and over again.
        </p>
        <p>
          <span className="fst-italic">Mind of the Market</span> integrates
          these timeless strategies into tools that are designed to make it easy
          for you to communicate with precision and deeply resonating impact.
        </p>
        <p>
          But what’s the secret behind Jay’s marketing success?{" "}
          <span className="fst-italic">Hear it from Jay himself!</span>
        </p>
        <div className="mom-newl-video-wrapper">
          <video controls>
            <source
              src="https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/landing-page-video/MoM+Landing+Page+Video.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </section>

      {/* Subconscious Copywriting Section */}
      <section className="mom-newl-subconscious-copywriting">
        <h2>
          For the First Time Ever: Write in a Way That Speaks to Your Audience’s
          Subconscious
        </h2>
        <p>
          With <span className="fst-italic">Mind of the Market</span>, you can
          write with a depth of understanding that goes beyond words on a page.
          Imagine creating messages that resonate with your audience at a
          subconscious level, making them feel seen, understood, and assured
          that you’re the trusted advisor they’ve been looking for.
        </p>
        <p>
          Most copywriters rely on formulaic templates — crafting messages
          without truly connecting to the heart of the market. They’ve never:
        </p>
        <ul>
          <li>
            Spent time listening directly to their audience's needs or
            challenges.
          </li>
          <li>
            Experienced the realities of customer service, understanding
            concerns firsthand.
          </li>
          <li>
            Sit in a sales room, face objections, or made the effort to truly
            understand their competition.
          </li>
        </ul>
        <p>…but not you!</p>
      </section>

      {/* Market Understanding Section */}
      <section className="mom-newl-market-understanding">
        <h2>Understand Your Market Like Never Before</h2>
        <p>
          The essence of effective copywriting lies in its ability to forge
          genuine connections, not merely through words but through
          understanding. It's about deeply understanding your audience's needs,
          challenges, and aspirations, and reflecting that understanding in
          every message you craft.
        </p>
        <p>
          With <span className="fst-italic">Mind of the Market</span>, we bring
          you the tools that delve into the psyche of your audience. By
          analyzing consumer behavior and feedback, it provides insights that
          allow you to speak directly to their core values and emotions. This
          isn't just about creating content, it's about creating a dialogue that
          resonates on a subconscious level, making your audience feel truly
          seen and understood.
        </p>
        <p>
          No other system brings you closer to the heartbeat of your market’s
          thoughts or helps you resonate with them at this level.
        </p>
        <h5 className="fw-bold mb-3">Join the Future of Copywriting</h5>
        <p>
          We understand the challenges you face — tight deadlines, ever-changing
          customer expectations, and the pressure to produce content that not
          only engages but converts.{" "}
          <span className="fst-italic">Mind of the Market</span> helps take the
          guesswork out of the equation, eliminating the frustration and
          inefficiencies of understanding your audience’s true needs.
        </p>
        <p className="fw-bold">
          Ready to transform the way you write and experience the power of
          deeper audience connection?
        </p>
      </section>

      {/* Trial Section */}
      <section className="mom-newl-trial">
        <h2>Start Your 30-Day Free Trial Today</h2>
        <p>
          Let us invest in you. We’re offering a 30-day trial so you can
          experience the transformative power of{" "}
          <span className="fst-italic">Mind of the Market</span> firsthand — no
          credit card required, no strings attached.
        </p>
        <p>
          <span className="fst-italic">Mind of the Market</span> handles the
          research, strategy, and structure that set your copy up for success.
          With just a little final polish, you’ll have copy that’s perfectly
          aligned, deeply resonant, and ready to connect with your audience like
          never before.
        </p>
        <p>
          Discover how our AI-powered platform, designed with human creativity
          in mind, makes your words come alive. Revolutionize the way you
          communicate and watch your message reach new heights.
        </p>
        <p className="fw-bold">
          Why wait? Start your 30-day trial today and experience how Mind of the
          Market makes exceptional copywriting easier and more accessible than
          ever.
        </p>
        <Link to={"/login"}>
          <button className="mom-newl-cta-button">Start Free Trial</button>
        </Link>
      </section>

      {/* Footer */}
      <footer className="mom-newl-footer">
        <h4>MIND OF THE MARKET</h4>
        <address>7520 Standish Place, St 260, Derwood MD 20866</address>
        <div className="mom-newl-social-links">
          <span>LinkedIn</span> | <span>Facebook</span> | <span>Instagram</span>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
