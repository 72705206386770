/* eslint-disable */
import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import addFileIcon from "../images/addfile_icon.svg";
import search from "../images/search_icon.svg";
import faq from "../images/faq.png";
import user from "../images/user.png";
import settings from "../images/settings.png";
import download from "../images/download.png";
import setupSupportTypes from "../images/setupSupportTypes.png";
import template from "../images/template.png";

import support from "../images/support.png";
import addToClipboard from "../images/addToClipboard.png";

import "./faq.css";
const Faq = () => {
  const faqData = [
    [
      "Mind of the Market - Projects Tutorial",
      "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/MoM+Tutorial+-+Projects.mp4",
    ],
    [
      "Mind of the Market - Market Research Tutorial",
      "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/MoM+Tutorial+-+Market+Research.mp4",
    ],
    [
      "Mind of the Market - Writer Tutorial",
      "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/MoM+Tutorial+-+Writer.mp4",
    ],
    // [
    //   "How to sign up?",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HowToSignup.mp4",
    //   "1.Go to home page and click on <b>Signup link.</b>",
    //   "2.Enter your <b>First Name</b>, <b>Last Name</b>, <b>Email</b>. <b>Phone number</b> and <b>Password</b>.",
    //   "3.<b>Agree to terms and policy</b> from Mind of the Market application",
    //   "4.Click on <b>GETOTP</b> to receive a six-digit code to email address chosen by you for user creation",
    // ],
    // [
    //   "How to login? ",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HowToLogin.mp4",
    //   "1.Go to home page and enter <b>username</b> and <b>password</b> ",
    //   "2.Click on <b>Login link</b> to login",
    //   "3. <b>Accept terms and conditions</b> to access Mind of the Market application",
    //   "4.You will be directed to <b>My Dashboards page</b>.",
    // ],
    // [
    //   "How to create projects?",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HowToCreateProjects.mp4",
    //   "1.Login to the application and you will be directed to <b>My Dashboards </b> screen.",
    //   `2.Click on Create new folder image
    //   <img style='background: #f2af32' src=${addFileIcon} height='20px' width='20px'  alt="Create new artifact"/>
    //   on top right-hand screen.`,
    //   "3.Enter <b> Project name</b> and name should be <b>Alpha numeric </b> and should not have any -, % or $ signs ",
    //   "4.Enter <b> description </b>, <b>Start date</b> and <b>Due date </b> as needed and click on <b>Create link</b> to start a new project.",
    // ],
    // [
    //   "How to search for books using keywords?",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HowToSearchForBooksUsingKeywords.mp4",
    //   "1.Click on any of the projects created by you to access <b> Search functionality </b>",
    //   `2.Click on <b>Search icon </b>
    //   <img style='background: #f2af32' src=${search} height='20px' width='20px'  alt="search"  />
    //   to enter keyword phrased to be searched for. phrase can contain spaces and up to 255 characters.`,
    //   "3.Press enter and wait for results displayed on the screen ",
    //   `4.You are able to download search results in tabular format by clicking on the <b>download image</b>
    //   <img style='background: #f2af32' src=${download} height='20px' width='20px'  alt="download"  />
    //   on top right side of the screen`,
    //   "Note: Results displayed will be linked to Amazon, Audible and Barnes & Nobles based on search criteria.ht side",
    // ],
    // [
    //   "How to use clipboard?",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HowToUseClipBoard.mp4",
    //   "1.Select a word or phrase that needs to be added to your clipboard with your mouse",
    //   `2.Right click on the phrase and click on image
    // <img style='background: #f2af32' src=${addToClipboard} height='20px' width='20px'  alt="add to clipboard"  />
    // <b>Add to Clipboard</b>.`,
    //   "3.Add description of clipboard phrase in a text as needed for future use. You can add any alphanumeric text for description",
    //   "4.To add categories, click on Add categories to create custom categories or use system defined categories for selected phrase added to clipboard based on need and future reference",
    // ],
    // [
    //   "How to create artifacts?",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HowToCreateArtifacts.mp4",
    //   `1.Click on <b>create new Artifact icon</b>
    // <img style='background: #f2af32' src=${addFileIcon} height='20px' width='20px'  alt="Create new artifact"/>
    //  on right hand side of Artifacts section of the page.`,
    //   "2.Select template to be used to create <b>Artifacts</b>.",
    //   "3.Enter Alphanumeric characters for <b>Artifact Name </b> and always avoid any special characters",
    //   "4.Enter data necessary for the template and save the template for current and future use",
    // ],
    // [
    //   "How to create users?",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HowToCreateUsers.mp4",
    //   `1.Login into the Mind of the market application bot and go to <b>Settings </b> on top right-hand screen and click on
    //   <img style='background: #f2af32' src=${settings} height='25px' width='25px'  alt="admin menu"/>
    //   `,
    //   `2.Please select <b>Users </b>
    //   <img style='background: #f2af32' src=${user} height='25px' width='25px'  alt="user icon"/>
    //    in the list of options displayed to you.`,
    //   `3.To create users, go to the
    // <img style='background: #f2af32' src=${addFileIcon} height='20px' width='20px'  alt="Create new user"/>
    // on the top right-hand screen of user screen page`,
    //   "4.Enter your <b>First Name, Last Name, Email and Password </b> and select <b>“Create”</b> button for the user.",
    // ],
    // [
    //   "How to request support?",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HowToRequestSupport.mp4",
    //   `1.Login to the Mind of the Market application and go to home page and click on
    // <img style='background: #f2af32' src=${support} height='20px' width='20px'  alt="Create new support request"/>
    // `,
    //   "2.Select <b>Support request type </b>that you need from support team.",
    //   "3.Enter <b>Request details</b> for support as requested by the support team.",
    // ],
    // [
    //   "How to create templates?",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HowToCreateTemplates.mp4",
    //   `1.Login into the Mind of the market application bot and go to <b>Settings</b> on top right-hand screen and click on Settings
    // <img style='background: #f2af32' src=${settings} height='25px' width='25px'  alt="admin menu"/>
    // `,
    //   `2.Please select <b>Templates</b>
    // <img style='background: #f2af32' src=${template} height='20px' width='20px'  alt="template icon"/>
    //  in the list of options displayed to you.`,
    //   `3.To create template, go to the
    // <img style='background: #f2af32' src=${addFileIcon} height='20px' width='20px'  alt="Create new template"/>
    // on the top right-hand screen of user screen page`,
    //   "4.Enter your <b>Template Name, Description</b> and if you wish to Add rows to template then enter Title and Description and select input type. Note: You can add multiple rows within one template and select different input type every time.",
    //   "5.Click on <b>Create</b> button to create a new template for end user",
    // ],
    // [
    //   "How to setup support types?",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HowToSetupSupportTypes.mp4",
    //   `1.Login into the Mind of the market application bot and go to Settings on top right-hand screen and click on <b>Settings</b>
    // <img style='background: #f2af32' src=${settings} height='25px' width='25px'  alt="admin menu"/>
    // `,
    //   `2.Please select <b>Setup Support Types </b>
    // <img style='background: #f2af32' src=${setupSupportTypes} height='25px' width='25px'  alt="support requests icon"/>
    // in the list of options.`,
    //   `3.To create support type, go to the
    // <img style='background: #f2af32' src=${addFileIcon} height='20px' width='20px'  alt="Create new support type"/>
    // on the top right-hand screen of user screen page`,
    //   "4.Enter <b>Support Type and Details</b> and select <b>“Create”</b> button to create new support type ",
    // ],
    // [
    //   "Help or FAQ’S",
    //   "https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/HelpOrFaq.mp4",
    //   `1. Login into the Mind of the market application bot and go to Help image
    //   <img style='background: #f2af32' src=${faq} height='20px' width='20px'  alt="faq"  />
    //   on top right-hand screen of your dashboard`,
    //   "2. Double click on this image and it will open a new browser window with FAQ pages and videos .",
    //   "3. You can toggle between 2 browsers for any further investigation.",
    // ],
  ];
  return (
    <>
      <div className="container-fluid ">
        <div className="px-2">
          <h3 className="mt-1 d-flex justify-content-center">
            Video Tutorials
          </h3>
          {/* <h5 className="d-flex justify-content-center">
            Tutorials to manage your account
          </h5> */}
          <Row className="row-lg-3 row-md-6 row-sm-12 row-xs-12">
            {faqData.map((item, index) => (
              <Col
                key={index}
                className="mt-2 col-lg-4 col-md-4 col-sm-12 col-xs-12 pb-3"
              >
                <Card className="faqCard ">
                  <video controls>
                    <source src={`${item[1]}`} type="video/mp4" />
                  </video>
                  <div className="card-body overflow-auto mt-2">
                    <h5 className="card-title">{item[0]}</h5>
                    {item.slice(2).map((faqDescription, index) => {
                      return (
                        <p
                          className="card-text"
                          key={index}
                          dangerouslySetInnerHTML={{ __html: faqDescription }}
                        />
                      );
                    })}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
};

export default Faq;
