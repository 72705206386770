/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { AxiosResponse } from "axios";
// import { useNavigate } from 'react-router-dom';

import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { toast } from "react-toastify";
import Pagination from "../Pagination";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../Utils/Api";
import SelectTemp from "../Modal/SelectTemplate";
import deleteIcon from "../../assets/images/delete_icon.svg";
import addFileIcon from "../../assets/images/addfile_icon.svg";
import "./artifact.css";
import { BsSearch } from "react-icons/bs";
import SelectTemplateModal from "../AiWriting/Modal/SelectTemplate";
import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";

interface Header {
  Authorization: string | null;
}
interface Artifact {
  ID: number;
  _id: string;
  NAME: string;
  PROJECT_ID: string;
}
interface allArtifacts {
  allArtifacts: Artifact[];
}
interface data {
  data: allArtifacts;
  message: string;
  status: number;
}

interface Project {
  _id: string;
  NAME: string;
}

interface artifactListResponse extends AxiosResponse {
  data: data;
}
function index() {
  const { projectId } = useParams();

  const navigate = useNavigate();

  const [artifactData, setArtifactsData] = useState<Array<Artifact>>([]);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [show, setShow] = useState(false);

  const [artifactId, setArtifactId] = useState<string | null>();

  const [showDelateModal, setShowDelateModal] = useState(false);

  const [projects, setProjects] = useState<Project[]>([]);

  const [selectTemplateModalOpened, setSelectTemplateModalOpened] =
    useState<boolean>(false);

  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const getArtifact = () => {
    AxiosGetWithToken(`/getArtifactsByProjectId/${projectId}`, {
      headers,
    }).then((response: artifactListResponse) => {
      setArtifactsData(response.data.data.allArtifacts);
    });
  };

  useEffect(() => {
    if (headers?.Authorization) {
      getArtifact();
    }
  }, []);

  const handleShow = (e) => {
    // setShow(!show);
    setSelectTemplateModalOpened(true);
    e.preventDefault();
  };

  const deleteArtifact = () => {
    AxiosPutWithToken(
      `/artifact/delete/${artifactId}/${projectId}`,
      {},
      { headers }
    )
      .then((response) => {
        getArtifact();
        const updatedList = artifactData.filter(
          (artifact: Artifact) => artifact._id !== artifactId
        );

        const renderData = updatedList.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        );

        if (renderData.length === 0) {
          setPage(page - 1);
        }
        setArtifactsData(updatedList);

        setArtifactId(null);

        toast.success(response.data.message);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await AxiosGetWithToken("/getProjectsByUserId", {
        headers,
      });
      setProjects(response.data.data.userProjects);
    } catch (err) {
      console.error("Failed to fetch projects", err);
    }
  };

  const navigateToArtifact = (artiFactId, item) => {
    navigate(`/project/${projectId}/writer/${artiFactId}`, {
      state: {
        artifactName: item.NAME,
        projectId: item.PROJECT_ID,
        projectName:
          projects.find((project) => project._id === item.PROJECT_ID)?.NAME ||
          "N/A",
        backPath: `/project/${item.PROJECT_ID}`,
        pathName: "Project Dashboard",
      },
    });
  };

  return (
    <Fragment>
      {/* <div className="d-flex w-100 align-items-center">
        <h5 className="mr-3">Documents</h5>

        <button
          type="button"
          className="btn btn-primary btn-circle mx-2 create-artifact"
          onClick={handleShow}
        >
          <img
            src={addFileIcon}
            alt="Create new artifact"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Create new artifact"
          />
        </button>
      </div> */}
      <div className="d-flex flex-wrap w-100">
        <h5 className="flex-fill flex-shrink-1">Writer</h5>
        <div className="d-flex flex-fill flex-grow-2 justify-content-end">
          <Button
            variant="filled"
            color="yellow"
            radius={"xl"}
            className={`float-end `}
            onClick={handleShow}
          >
            <IconPlus className="me-2" size={18} stroke={2} />
            Create new
          </Button>
        </div>
      </div>
      {/* <div className="d-flex flex-wrap w-100">
        <h5 className="flex-fill flex-shrink-1">Create new artifacts</h5>
        <div className="d-flex flex-fill flex-grow-2">
          <div className="flex-fill"></div>
          <div className="flex-shrink-1">
            <button
              type="button"
              className="btn btn-primary btn-circle mx-2"
              onClick={handleShow}
            >
              <img
                src={addFileIcon}
                alt="Create new artifact"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Create new artifact"
              />
            </button>
          </div>
        </div>
      </div> */}
      <Table responsive="sm" className="table w-100 mt-3 table-bordered ">
        <thead className="colHeading">
          <tr>
            <th>Document name</th>
            <th></th>
          </tr>
        </thead>
        {artifactData.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={4}>No artifacts found</td>
            </tr>
          </tbody>
        ) : (
          artifactData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((artifactName: Artifact, index: number) => (
              <tbody key={index}>
                <tr>
                  <td data-label="Artifact name" className="wrapText">
                    <span
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Edit artifact"
                    >
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          navigateToArtifact(artifactName._id, artifactName);
                        }}
                      >
                        {artifactName.NAME}
                      </a>
                    </span>
                  </td>
                  <td data-label="">
                    <span
                      className="float-md-end"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Delete"
                    >
                      <button
                        className="btn-del"
                        onClick={() => {
                          setArtifactId(artifactName._id);
                          setShowDelateModal(true);
                        }}
                      >
                        <img src={deleteIcon} />
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            ))
        )}
      </Table>

      {artifactData.length > 5 && (
        <Pagination
          data={artifactData}
          rowsPerPage={rowsPerPage}
          page={page}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      )}

      <SelectTemp show={show} setShow={setShow} />

      {showDelateModal && (
        <ConfirmationDialog
          confirmButtonText="Delete"
          confirmDialogTitle="Delete artifact?"
          show={showDelateModal}
          setShow={setShowDelateModal}
          onConfirm={deleteArtifact}
          message="Are you sure you want to delete the selected artifact ?"
          isConfirmButtonVisible={true}
        />
      )}
      <SelectTemplateModal
        opened={selectTemplateModalOpened}
        onClose={() => setSelectTemplateModalOpened(false)}
        refreshDocuments={getArtifact}
        isProject
        projectObjectId={projectId}
      />
    </Fragment>
  );
}

export default index;
