/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  IconFolder,
  IconWorldSearch,
  IconBookmark,
  IconAffiliate,
  IconWriting,
  IconPlayerPlay,
  IconReservedLine,
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import "./newhome.scss";
import { useDispatch, useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { AxiosGetWithToken, AxiosPutWithToken } from "../../Utils/Api";
import { userData } from "../../Redux/Action/user";
import { toast } from "react-toastify";
import CommonHeader from "../../Components/CommonHeader/CommonHeader";
import {
  Badge,
  Button,
  Dialog,
  Group,
  TextInput,
  Text,
  Flex,
  Paper,
  CloseButton,
  Divider,
  Modal,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconArrowRight } from "@tabler/icons-react";

const NewHome: React.FC = () => {
  const dispatch = useDispatch();

  interface data {
    message?: string;
    status?: number;
    FIRST_NAME: string;
    EMAIL: string;
    LAST_NAME: string;
    TYPE?: string;
    IS_SUBSCRIBE?: string;
    PLAN_AMOUNT?: number;
    HAS_WATCHED_TUTORIAL?: boolean;
  }

  // eslint-disable-next-line no-unused-vars
  enum status {
    // eslint-disable-next-line no-unused-vars
    Unsubscribe = "Unsubscribe",
    // eslint-disable-next-line no-unused-vars
    Resubscribe = "Re-subscribe",
  }
  interface userDetailsResponse extends AxiosResponse {
    data: { data: data };
  }

  interface Header {
    Authorization: string | null;
  }

  interface Event {
    preventDefault: Function;
  }
  const headers: Header = {
    Authorization: localStorage.getItem("accessToken"),
  };

  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 50em)");

  const [tutorialDialogOpened, setTutorialDialogOpened] =
    useState<boolean>(false);

  const userDetail = useSelector(
    (state: { UserReducer: { userDetail: data } }) =>
      state.UserReducer.userDetail
  );

  const [isTutorialWatchedSaving, setIsTutorialWatchedSaving] =
    useState<boolean>(false);

  const getUserDetails = () => {
    AxiosGetWithToken("/user", { headers }).then(
      (response: userDetailsResponse) => {
        localStorage.setItem("email", response.data.data.EMAIL);
        dispatch(userData(response.data.data));
        const hasTutorialWatched = response.data.data.HAS_WATCHED_TUTORIAL;
        if (!hasTutorialWatched || hasTutorialWatched == undefined) {
          setTutorialDialogOpened(true);
        }
      }
    );
  };

  useEffect(() => {
    if (headers?.Authorization) {
      getUserDetails();
    } else {
      setTutorialDialogOpened(false);
    }
  }, []);

  const handleTutorialWatched = async () => {
    setIsTutorialWatchedSaving(true);
    try {
      await AxiosPutWithToken(
        `/users/tutorial`,
        { HAS_WATCHED_TUTORIAL: true },
        { headers }
      );
      setIsTutorialWatchedSaving(false);
      setTutorialDialogOpened(false);
    } catch (err) {
      console.error("Failed to update document", err);
      setIsTutorialWatchedSaving(false);
      toast.error("Failed to update document");
    }
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.7 },
  };

  return (
    <div className="vh-100 d-flex flex-column">
      <CommonHeader />
      <Container
        className="flex-grow-1 d-flex flex-column justify-content-center align-items-center"
        style={{ marginTop: isMobile ? "50px" : "" }}
      >
        <motion.h2 {...fadeInUp}>Where do you want to go?</motion.h2>
        <Row className="mt-4 justify-content-center">
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card
                className="option-card pro d-flex flex-column justify-content-center align-items-center"
                onClick={() => {
                  navigate("/project");
                }}
              >
                <Badge variant="dot" size="sm" className="coming-soon-badge">
                  New
                </Badge>
                <IconFolder size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Projects</Card.Text>
              </Card>
            </motion.div>
          </Col>
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card
                className="option-card webscrape d-flex flex-column justify-content-center align-items-center"
                onClick={() => {
                  navigate("/webscrape");
                }}
              >
                <Badge variant="dot" size="sm" className="coming-soon-badge">
                  New
                </Badge>
                <IconWorldSearch size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Market Research</Card.Text>
              </Card>
            </motion.div>
          </Col>
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card
                className="option-card aiwriting d-flex flex-column justify-content-center align-items-center"
                onClick={() => {
                  navigate("/writer");
                }}
              >
                <Badge variant="dot" size="sm" className="coming-soon-badge">
                  New
                </Badge>
                <IconWriting size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Writer</Card.Text>
              </Card>
            </motion.div>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card
                className="option-card bookmarks d-flex flex-column justify-content-center align-items-center"
                onClick={() => {
                  navigate("/bookmarks");
                }}
              >
                <Badge variant="dot" size="sm" className="coming-soon-badge">
                  New
                </Badge>
                <IconBookmark size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Bookmarks</Card.Text>
              </Card>
            </motion.div>
          </Col>
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card className="option-card workflows d-flex flex-column justify-content-center align-items-center">
                <Badge
                  variant="default"
                  size="sm"
                  className="coming-soon-badge"
                >
                  Coming Soon
                </Badge>
                <IconAffiliate size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Workflows</Card.Text>
              </Card>
            </motion.div>
          </Col>
          <Col xs="auto" className="p-2">
            <motion.div {...fadeInUp}>
              <Card
                className="option-card jayss d-flex flex-column justify-content-center align-items-center"
                onClick={() => {
                  navigate("/marketing-video-workbook");
                }}
              >
                <Badge variant="dot" size="sm" className="coming-soon-badge">
                  New
                </Badge>
                <IconReservedLine size={50} stroke={1} className="icon mb-2" />
                <Card.Text className="label">Jay's Resources</Card.Text>
              </Card>
            </motion.div>
          </Col>
        </Row>
        {tutorialDialogOpened == true ? null : (
          <Button
            variant="light"
            color="yellow"
            size="sm"
            radius={50}
            mt={12}
            rightSection={<IconArrowRight size={20} />}
            onClick={() => {
              setTutorialDialogOpened(true);
            }}
            style={{ cursor: "pointer", marginBottom: isMobile ? "50px" : "" }}
          >
            Watch Mind of the Market Quick Start Tutorial
          </Button>
        )}
      </Container>
      <Modal
        opened={tutorialDialogOpened}
        onClose={() => {
          handleTutorialWatched();
        }}
        title={
          <Text size="xl" fw={700} c={"#333"}>
            Mind of the Market Quick Start Tutorial
          </Text>
        }
        size="60%"
        fullScreen={isMobile}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
        centered
        radius={15}
      >
        <video controls width={"100%"}>
          <source
            src="https://mom-bot-data-v1.s3.us-west-1.amazonaws.com/faq-videos/MoM+Quickstart+Tutorial.mp4"
            type="video/mp4"
          />
        </video>

        <Flex mt="md" justify="flex-end" gap="xs" align="center">
          <Button
            variant="default"
            radius="md"
            onClick={() => {
              setTutorialDialogOpened(false);
            }}
          >
            Watch Later
          </Button>
          <Button
            variant="filled"
            radius="md"
            onClick={() => {
              handleTutorialWatched();
            }}
            loading={isTutorialWatchedSaving}
          >
            Skip
          </Button>
        </Flex>
      </Modal>
    </div>
  );
};

export default NewHome;
