/* eslint-disable */
import React, { Fragment } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import SearchResultList from "../Pages/SearchResult/index";
import NotFound from "../Components/NotFound/index";
import Signup from "../Pages/Auth/SignUp";
import Login from "../Pages/Auth/Login";
import ProjectDashboard from "../Pages/ProjectDashboard/index";
import Home from "../Pages/Home/index";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import ProtectedRoutes from "./ProtectedRoutes";
import AdminProtectedRoutes from "./AdminProtectedRoutes";
import Template from "../Pages/Template";
import CreateTemplate from "../Pages/Template/CreateNewTemplate";
import PredefinedCategory from "../Pages/PredefinedCategory/index";
// import TermsAndCondition from '../Pages/Auth/Login/TermsAndCondition';
import SupportRequestType from "../Pages/SupportRequestType/index";
import ManagesSupportRequest from "../Pages/Manage support requests/index";
import Resubscribe from "../Pages/Auth/Resubscribe";
import UserList from "../Pages/UserList/index";
import MarketingVideos from "../Pages/MarketingVideos/index";
import Faq from "../assets/faq/Faq";
import HomePage from "../assets/landing/main/Landing";
import AboutPage from "../assets/landing/about/About";
import ContactPage from "../assets/landing/contact/Contact";
import NewHome from "../Pages/NewHome";
import Bookmarks from "../Pages/Bookmarks";
import BookmarkContent from "../Pages/Bookmarks/BookmarkContent";
import AiWritingPage from "../Pages/AiWriting";
import AiWritingEditor from "../Pages/AiWriting/AiWritingEditor";
import WebScrapePage from "../Pages/WebScrape";
import SearchResult from "../Pages/WebScrape/SearchResult";
import Projects from "../Pages/Projects";
import Dashboard from "../Pages/Projects/Dashboard";
import NewHomePage from "../assets/landing/new/NewLanding";

const AddArtifact = React.lazy(
  () => import("../Components/Artifacts/AddArtifact")
);

function RoutesPage() {
  const token = localStorage.getItem("accessToken");

  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<NewHomePage />} />
          <Route path="/email" element={<NewHomePage />} />
          <Route path="/jay" element={<NewHomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route
            path="/login"
            element={token ? <Navigate to="/home" /> : <Login />}
          />
          <Route
            path="/signup"
            element={token ? <Navigate to="/home" /> : <Signup />}
          />
          <Route
            path="/forgot-password"
            element={token ? <Navigate to="/home" /> : <ForgotPassword />}
          />
          <Route path="/user-guide" element={<Faq />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/landing" element={<HomePage />} />
          {/* <Route path="/writer" element={<AIWritingPage />} /> */}

          {/* Protected Routes */}
          <Route
            path="/home"
            element={<ProtectedRoutes Component={NewHome} token={token} />}
          />

          <Route
            path="/project"
            element={<ProtectedRoutes Component={Projects} token={token} />}
          />

          <Route
            path="/project/:projectId"
            element={
              <ProtectedRoutes
                Component={Dashboard}
                token={token}
                showbutton="buton"
              />
            }
          />

          <Route
            path="/project/:projectId/writer/:id"
            element={
              <ProtectedRoutes Component={AiWritingEditor} token={token} />
            }
          />

          <Route
            path="/project/:projectId/viewSearchResults/:id"
            element={<ProtectedRoutes Component={SearchResult} token={token} />}
          />

          <Route
            path="/project/:projectId/performSearch/:id"
            element={<ProtectedRoutes Component={SearchResult} token={token} />}
          />

          <Route
            path="/webscrape"
            element={
              <ProtectedRoutes Component={WebScrapePage} token={token} />
            }
          />

          <Route
            path="/webscrape/viewSearchResults/:id"
            element={<ProtectedRoutes Component={SearchResult} token={token} />}
          />

          <Route
            path="/webscrape/performSearch/:id"
            element={<ProtectedRoutes Component={SearchResult} token={token} />}
          />

          <Route
            path="/writer"
            element={
              <ProtectedRoutes Component={AiWritingPage} token={token} />
            }
          />

          <Route
            path="/writer/:id"
            element={
              <ProtectedRoutes Component={AiWritingEditor} token={token} />
            }
          />

          <Route
            path="/bookmarks"
            element={<ProtectedRoutes Component={Bookmarks} token={token} />}
          />
          <Route
            path="/bookmarks/:id"
            element={
              <ProtectedRoutes Component={BookmarkContent} token={token} />
            }
          />

          {/* <Route
            path="/projects"
            element={<ProtectedRoutes Component={Home} token={token} />}
          />
          <Route
            path="/projectDashboard/:projectId"
            element={
              <ProtectedRoutes
                Component={ProjectDashboard}
                token={token}
                showbutton="buton"
              />
            }
          />
          <Route
            path="/project/:projectId/viewSearchResults/:keyword"
            element={
              <ProtectedRoutes Component={SearchResultList} token={token} />
            }
          />
          <Route
            path="/project/:projectId/performSearch/:keyword"
            element={
              <ProtectedRoutes Component={SearchResultList} token={token} />
            }
          /> */}
          <Route
            path="/template"
            element={
              <AdminProtectedRoutes Component={Template} token={token} />
            }
          />
          <Route
            path="/create/template"
            element={
              <AdminProtectedRoutes Component={CreateTemplate} token={token} />
            }
          />
          <Route
            path="/edit/template/:templateId"
            element={
              <AdminProtectedRoutes Component={CreateTemplate} token={token} />
            }
          />
          <Route
            path="/predefinedCategory"
            element={
              <AdminProtectedRoutes
                Component={PredefinedCategory}
                token={token}
              />
            }
          />
          <Route
            path="/addArtifact/:projectId/:templateId"
            element={<ProtectedRoutes Component={AddArtifact} token={token} />}
          />
          <Route
            path="/editArtifact/:projectId/:editArtifactId"
            element={<ProtectedRoutes Component={AddArtifact} token={token} />}
          />
          {/* <Route path="/termsAndCondition" element={token ? <Navigate to="/home" /> : <TermsAndCondition/>} /> */}
          <Route
            path="/supportRequestType"
            element={
              <AdminProtectedRoutes
                Component={SupportRequestType}
                token={token}
              />
            }
          />
          <Route
            path="/managesSupportRequest"
            element={
              <AdminProtectedRoutes
                Component={ManagesSupportRequest}
                token={token}
              />
            }
          />
          <Route
            path="/userList"
            element={
              <AdminProtectedRoutes Component={UserList} token={token} />
            }
          />
          <Route
            path="/marketing-video-workbook"
            element={
              <ProtectedRoutes Component={MarketingVideos} token={token} />
            }
          />
          <Route
            path="/subscribe"
            element={token ? <Navigate to="/home" /> : <Resubscribe />}
          />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default RoutesPage;
